import * as PropTypes from "prop-types"
import { useLocale, useT } from "react-polyglot-hooks"
import styled from "styled-components"
import Typography from "components/styled/Typography"
import LandingSeo from "components/SEO/LandingSeo"
import HeaderButtons from "components/landing/HeaderButtons"
import Car from "models/car"
import { color, mq } from "helpers/styled"
import { capitalizeFirstLetter } from "helpers/formatter"
import ResponsiveImage from "components/ResponsiveImage"
import Link from "components/Link"
import kebabCase from "lodash/kebabCase"
import { sendEvent } from "scripts/GoogleAnalytics"
import { useContext } from "react"
import { HomeContext } from "scenes/HomeScene/HomeContext"
import dynamic from "next/dynamic"

const DownPayment = dynamic(() => import("components/landing/DownPayment"))

const FeaturedData = styled.div`
  color: ${color("darkBlue")};
  position: absolute;
  top: -10px;
  left: 25px;
  z-index: 1;

  @media ${mq("md")} {
    top: 25px;
    left: 150px;
  }

  @media ${mq("lg")} {
    left: 335px;
  }

  @media ${mq("xl")} {
    left: calc(628px - 130px);
  }

  // Brand modeln
  > :nth-child(1) {
    display: block;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 5px;

    @media ${mq("md")} {
      font-size: 24px;
      margin-bottom: 10px;
    }
  }

  // Desde
  > :nth-child(2) {
    display: block;
    font-size: 10px;
    line-height: 1.4;

    @media ${mq("md")} {
      margin-bottom: 5px;
    }
  }

  // minimumPrice€/mes
  > :nth-child(3) {
    display: block;
    height: 25px;
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
    color: ${color("deepOrange")};

    > span {
      font-weight: bold;
      font-size: 30px;
    }

    @media ${mq("lg")} {
      height: 30px;
    }
  }

  > :nth-child(4) {
    font-style: italic;
  }
`

const TaxIncluded = styled(Typography)`
  @media (max-width: 991.9px) {
    font-size: 10px;
    line-height: 14px;
  }
`

const ImageContainer = styled.div`
  position: relative;
  height: 233px;
  width: 100%;
  max-width: 375px;
  margin: 35px auto 0;
  padding: 0 25px;

  @media ${mq("md")} {
    margin-top: 25px;
    height: 278px;
    max-width: 768px;
    padding: 0 50px;
  }

  @media ${mq("lg")} {
    margin-top: 0;
    margin-bottom: 25px;
    max-width: 1024px;
    padding: 0 90px;
  }

  @media ${mq("xl")} {
    max-width: 1200px;
    padding: 0 90px;
    height: 372px;
    margin-top: -100px;
    margin-bottom: 0;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  height: 100%;

  img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
  }

  // Background
  > :nth-child(1) img {
    max-width: none;
    max-height: none;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @media ${mq("lg")} and (max-width: 1200px) {
      left: calc(50% + 25px);
    }
  }

  // Car
  > :nth-child(2) img {
    min-width: 100%;
    max-height: none;
    max-width: none;
    top: 0;
    right: -10px;

    @media ${mq("md")} {
      right: 26px;
      min-width: 0;
    }

    @media ${mq("lg")} {
      right: 0;
    }
  }

  // Man
  > :nth-child(3) {
    height: 131px;
    right: -10px;
    bottom: 5px;

    @media ${mq("md")} {
      height: 195px;
      right: -35px;
    }

    @media ${mq("lg")} {
      height: 212px;
      right: -40px;
    }

    @media ${mq("xl")} {
      height: 257px;
      right: -115px;
      bottom: 5px;
    }
  }

  // Woman
  > :nth-child(4) {
    height: 132px;
    left: -15px;
    bottom: 10px;

    @media ${mq("md")} {
      height: 193px;
      left: 0;
      bottom: 15px;
    }

    @media ${mq("lg")} {
      left: 135px;
      height: 215px;
      bottom: 5px;
    }

    @media ${mq("xl")} {
      left: 250px;
      height: 260px;
      bottom: 15px;
    }
  }
`

const CarImage = styled.img`
  background-image: url("${`${process.env.NEXT_PUBLIC_CLOUDINARY}v1618349751/static/home/background-highlight.svg`}");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -100px;
`

const Background = styled.div`
  position: relative;
  padding-top: 100px;
  background: white;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    background-color: ${color("paleGray")};
    height: 100%;
    width: 4000px;
    min-width: 100%;
    top: 9%;
    left: 50%;
    transform-origin: 100% 0;
    transform: translateX(-50%) skewY(-11deg);
    overflow: hidden;

    @media ${mq("md")} {
      top: 31%;
      transform: translateX(-50%) skewY(-7deg);
    }

    @media ${mq("lg")} {
      top: 27%;
    }

    @media ${mq("xl")} {
      top: 32%;
      transform: translateX(-50%) skewY(-5deg);
    }
  }
`

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  position: relative;

  @media ${({ theme }) => theme.breakpoints.xl} {
    max-width: 1230px;
  }
`

const Content = styled.div`
  padding: 0 25px;
  text-align: center;
  margin: 0 auto;
  z-index: 1;
  position: relative;

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding-top: 17px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    padding-top: 0;
    text-align: left;
    max-width: 894px;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    padding-top: 0;
    text-align: left;
    max-width: 1230px;
  }
`

const Title = styled(Typography)`
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 28px;

  span {
    display: block;
  }

  @media (min-width: 340px) {
    font-size: 28px;
    line-height: 32px;
  }

  @media ${mq("xs")} {
    font-size: 30px;
    line-height: 1.19;
  }

  @media ${mq("sm")} {
    font-size: 36px;
    line-height: 1;
  }

  @media ${mq("lg")} {
    margin-bottom: 20px;
  }
`

const Subtitle = styled(Typography)`
  font-size: 16px;
  line-height: 21px;
  margin: 0;
  color: ${color("darkBlue")};

  @media ${mq("md")} {
    font-size: 18px;
  }
`

const TextBlock = styled.div`
  margin: 0 auto 30px;
  max-width: 450px;

  @media ${mq("lg")} {
    margin: 0 0 30px;
  }
`

const carSize = {
  xl: 593,
  lg: 489,
  md: 444,
  xs: 264
}

export default function Header({ landing, featuredOffer }) {
  const t = useT()
  const locale = useLocale()
  const { brand, modelName, minimumPrice, slug, brandImageUrlPath, modelImageUrlPath } = new Car(featuredOffer)
  const slugParts = slug.split("/")
  const { featuredImage } = useContext(HomeContext)

  const getImageUrl = (w, factor = 1) => {
    const width = `w_${w * factor},`

    return featuredImage
      ? `${process.env.NEXT_PUBLIC_CLOUDINARY}${width}/v1/car_model/${brandImageUrlPath}/${modelImageUrlPath}_featured_${locale}`
      : `${process.env.NEXT_PUBLIC_CLOUDINARY}${width}c_pad,q_auto,g_south/v1/car_model/${brandImageUrlPath}/${modelImageUrlPath}`
  }

  const handleFeaturedClick = () => {
    sendEvent("featured car", {
      event_category: landing,
      event_label: "featured car click"
    })
  }

  return (
    <>
      <LandingSeo landing={landing} />
      <Wrapper className={landing}>
        <Background>
          <Container>
            <Content className={landing}>
              <TextBlock>
                <Title variant="title1" weight="bold">
                  {t(`${landing}.exclusive.title`)}
                </Title>
                <Subtitle variant="title2">{t(`${landing}.exclusive.subtitle`)}</Subtitle>
              </TextBlock>
              <HeaderButtons page={landing} />
            </Content>
            <div>
              <ImageContainer>
                <FeaturedData>
                  <span>{`${brand} ${modelName}`}</span>
                  <span>{capitalizeFirstLetter(t("common.from"))}</span>
                  <span>
                    <span>{minimumPrice}</span>
                    {t("common.currency")}
                  </span>
                  <TaxIncluded el="span" variant="small">
                    {t("common.tax_included")}
                  </TaxIncluded>
                  {locale === "it" && <DownPayment downPayment={featuredOffer.downPayment} />}
                </FeaturedData>
                <ImageWrapper>
                  <ResponsiveImage
                    alt="Background"
                    url="https://res.cloudinary.com/dag3rmkxg/image/upload/v1614174885/static/home/exclusive-bg"
                    highRes={false}
                    ext="svg"
                  />
                  <Link page="version" params={{ brand: kebabCase(brand), model: slugParts[1], version: slugParts[2] }}>
                    {/* eslint-disable-next-line */}
                    <a onClick={handleFeaturedClick}>
                      <picture>
                        <source
                          srcSet={`${getImageUrl(carSize.xl)},
                              ${getImageUrl(carSize.xl, 2)} 2x,
                              ${getImageUrl(carSize.xl, 3)} 3x`}
                          media={mq("xl")}
                        />
                        <source
                          srcSet={`${getImageUrl(carSize.lg)},
                              ${getImageUrl(carSize.lg, 2)} 2x,
                              ${getImageUrl(carSize.lg, 3)} 3x`}
                          media={mq("lg")}
                        />
                        <source
                          srcSet={`${getImageUrl(carSize.md)},
                              ${getImageUrl(carSize.md, 2)} 2x,
                              ${getImageUrl(carSize.md, 3)} 3x`}
                          media={mq("md")}
                        />
                        <source
                          srcSet={`${getImageUrl(carSize.xs)},
                              ${getImageUrl(carSize.xs, 2)} 2x,
                              ${getImageUrl(carSize.xs, 3)} 3x`}
                          media={mq("xs")}
                        />
                        {featuredImage ? (
                          <img alt="Car" src={`${getImageUrl(carSize.xs)}`} />
                        ) : (
                          <CarImage alt="Car" src={`${getImageUrl(carSize.xs)}`} />
                        )}
                      </picture>
                    </a>
                  </Link>
                  <img
                    src="https://res.cloudinary.com/dag3rmkxg/image/upload/v1614174886/static/home/exclusive-man.svg"
                    alt="Man"
                  />
                  <img
                    src="https://res.cloudinary.com/dag3rmkxg/image/upload/v1614174886/static/home/exclusive-woman.svg"
                    alt="Woman"
                  />
                </ImageWrapper>
              </ImageContainer>
            </div>
          </Container>
        </Background>
      </Wrapper>
    </>
  )
}

Header.defaultProps = {
  landing: "home"
}

Header.propTypes = {
  landing: PropTypes.string,
  featuredOffer: PropTypes.object.isRequired
}
